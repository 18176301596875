import { openModal } from "./modal.js";

console.log("main.js loaded");

document.addEventListener("DOMContentLoaded", () => {
  console.log("DOM fully loaded");

  const burgerButton = document.querySelector(".burger-button");
  console.log("Burger button:", burgerButton);

  if (burgerButton) {
    burgerButton.addEventListener("click", () => {
      console.log("Burger button clicked");
      openModal(
        ".burger",
        ".burger-content",
        ".burger-close",
        "burger-is-open",
        {
          linksInsideCloseModal: true,
        },
      );
    });
    console.log("Click event listener added to burger button");
  } else {
    console.error("Burger button not found");
  }
});

// Дополнительная проверка после небольшой задержки
setTimeout(() => {
  const burgerButtonAfterDelay = document.querySelector(".burger-button");
  console.log("Burger button after delay:", burgerButtonAfterDelay);
}, 1000);
